import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import { LicenseManager } from "ag-grid-enterprise";

//LicenseManager.setLicenseKey('CompanyName=Insight (Orem, UT)_on_behalf_of_Unitedhealth Group Incorporated,LicensedGroup=DMS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-017396,ExpiryDate=19_August_2022_[v2]_MTY2MDg2MzYwMDAwMA==10e3d5bd2d7798613e40f5c0094531aa')
// LicenseManager.setLicenseKey(
//   "CompanyName=Insight (Orem, UT)_on_behalf_of_Unitedhealth Group Incorporated,LicensedGroup=DMS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-030442,SupportServicesEnd=19_August_2023_[v2]_MTY5MjM5OTYwMDAwMA==e9b2ce73e7148c25320c0323b608711c"
// );
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-042021 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( United Health Group )_is_granted_a_( Multiple Applications )_Developer_License_for_( 10 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 18 August 2024 )____[v2]_MTcyMzkzNTYwMDAwMA==89abe1b0deb326ee2e3a62cf0febb7a7')

const theme = createTheme({
  palette: {
    primary: { main: "#002677", contrastText: "white" }, // blue
    secondary: { main: "#e77721", light: "#ffd6b8", contrastText: "white" }, // orange
    type: "light",
  },
});

theme.components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none", // Remove upper case on MUI buttons
        fontSize: "0.8vw",
        height: "2vw",
        width: '40%',
        minWidth: '40%'
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
          {
            fontSize: "0.8vw",
            height: "3vw",
            padding: 0,
          },
        "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
          fontSize: "0.8vw",
          height: "3vw",
          padding: 0,
        },
        "& .MuiInputLabel-root": {
          fontSize: "0.8vw",
          height: "3vw",
          padding: 0,
        },
      },

      ul: {
        padding: 0,
      },
      option: {
        fontSize: ".8vw",
        height: "2vw",
        width: "100%",
        padding: 0,
      },
      tag: {
        fontSize: "0.7vw",
        height: "1.2vw",
        padding: 0,
        backgroundColor: "#e77721",
        color: "white",
        "& .MuiChip-deleteIcon": {
          color: "white",
          height: "1vw",
          width: "1vw",
        },
        "&:hover .MuiChip-deleteIcon": {
          color: "#f5f5f5",
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiInputBase-root, & .MuiInputLabel-root": {
          fontSize: "0.8vw",
          height: "3vw",
        },
        "& .MuiInputLabel-root.MuiInputLabel-shrink": {
          // With the smaller font size, the shrink effect
          // shouldn't go quite as high ("-6px" instead of "-9px")
          // in order for it to look correct.
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        fontSize: "0.8vw",
        height: "2vw",
        padding: 0,
      },
      inputRoot: {
        padding: 0,
      },
    },
  },

  MuiTypography: {
    variants: [
      {
        props: { variant: "h6" },
        style: {
          fontSize: "1vw",
        },
      },
    ],
  },
  MuiTabs: {
    styleOverrides: {
      root: {},
      flexContainer: {
        alignItems: "end",
      },
      indicator: {
        backgroundColor: "#e77721",
        height: "3px",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        backgroundColor: "#f7f9fa",
        fontWeight: "bold",
        borderTop: "1px solid #b4bbbf",
        borderLeft: "1px solid #b4bbbf",
        borderRight: "1px solid #b4bbbf",
        borderRadius: "5px 5px 5px 0",
        height: "40px",
        color: "#002677",
        "&.Mui-selected": {
          color: "#FF612B",
          backgroundColor: "white",
          height: "54px",
        },
        "&:hover": {
          backgroundColor: "#fafafa",
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      thumb: {
        height: "1vw",
        width: "1vw",
        // backgroundColor: "#fff",
        // border: "1px solid currentColor",
        // marginTop: -9,
        // marginLeft: -11,
        // boxShadow: "#ebebeb 0 2px 2px",
        // "&:focus, &:hover, &$active": {
        //   boxShadow: "#ccc 0 2px 3px 1px",
        // },
        // color: "#fff",
      },
    },
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import reportWebVitals from "./reportWebVitals";
// import { LicenseManager } from "ag-grid-enterprise";
// LicenseManager.setLicenseKey(
//   "CompanyName=Insight (Orem, UT)_on_behalf_of_Unitedhealth Group Incorporated,LicensedGroup=DMS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-030442,SupportServicesEnd=19_August_2023_[v2]_MTY5MjM5OTYwMDAwMA==e9b2ce73e7148c25320c0323b608711c"
// );

// const theme = createTheme({
//   breakpoints: {
//     values: {
//       // mobile: 0,
//       // tablet: 640,
//       laptop: 1400,
//       smallDesktop: 1600,
//       desktop: 1800,
//     },
//   },
//   palette: {
//     primary: { main: "#002677", contrastText: "white" }, // blue
//     secondary: { main: "#e77721", light: "#ffd6b8", contrastText: "white" }, // orange
//     type: "light",
//   },
// });

// theme.components = {
//   MuiButton: {
//     styleOverrides: {
//       root: {
//         textTransform: "none", // Remove upper case on MUI buttons
//       },
//     },
//   },
//   MuiAutocomplete: {
//     styleOverrides: {
//       root: {
//         "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root":
//           {
//             [theme.breakpoints.down("laptop")]: {
//               padding: 0,
//               fontSize: 14,
//             },
//           },
//         "&.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root": {
//           [theme.breakpoints.down("laptop")]: {
//             padding: 0,
//           },
//         },
//         "& .MuiInputLabel-root": {
//           [theme.breakpoints.down("laptop")]: {
//             fontSize: 14,
//             padding: 2,
//           },
//         },
//       },
//       paper: {
//         [theme.breakpoints.down("laptop")]: {
//           fontSize: 14,
//           padding: 2,
//         },
//       },
//       ul: {
//         padding: 0,
//       },
//       tag: {
//         backgroundColor: "#e77721",
//         color: "white",
//         "& .MuiChip-deleteIcon": {
//           color: "white",
//         },
//         "&:hover .MuiChip-deleteIcon": {
//           color: "#f5f5f5",
//         },
//       },
//     },
//   },
//   MuiTextfield: {
//     styleOverrides: {
//       root: {
//         padding: 0,
//       },
//     },
//   },
//   MuiTypography: {
//     variants: [
//       {
//         props: { variant: "h6" },
//         style: {
//           [theme.breakpoints.between("desktop", "laptop")]: {
//             fontSize: 8,
//           },
//         },
//       },
//     ],
//   },
//   MuiTabs: {
//     styleOverrides: {
//       root: {},
//       flexContainer: {
//         alignItems: "end",
//       },
//       indicator: {
//         backgroundColor: "#e77721",
//         height: "3px",
//       },
//     },
//   },
//   MuiTab: {
//     styleOverrides: {
//       root: {
//         backgroundColor: "#fdfdfd",
//         fontWeight: "bold",
//         borderTop: "1px solid #b4bbbf",
//         borderLeft: "1px solid #b4bbbf",
//         borderRight: "1px solid #b4bbbf",
//         borderRadius: "5px 5px 5px 0",
//         // fontFamily: 'Optum Sans',
//         height: "40px",
//         color: "#002677",
//         "&.Mui-selected": {
//           color: "#FF612B",
//           backgroundColor: "white",
//           height: "54px",
//         },
//         "&:hover": {
//           backgroundColor: "#fafafa",
//         },
//       },
//     },
//   },
// };

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <ThemeProvider theme={theme}>
//     <App />
//   </ThemeProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
