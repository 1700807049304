import React, { useState } from "react";
import { Card, Container, Row, Col, Modal } from "react-bootstrap";
import pjson from "../package.json";
import moment from "moment";
import _ from "underscore";
import { generateToken, post6c } from "./utils";
import Registration from "./Registration";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import localStorage from "react-secure-storage";

const Login = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    u: "",
    p: "",
    value: "Enter MS Logon",
    userDetails: {},
    auth: false,
    error: false,
    environment: process.env.REACT_APP_ENV || "Dev",
    environments: [
      {
        short: "Prod",
        long: "Production",
      },
      {
        short: "Stg",
        long: "Stage",
      },
      {
        short: "Dev",
        long: "Development",
      },
    ],
    showEnv: false,
    showRegistration: false,
    data: null,
    alertInfo: null,
    errorInfo: null,
  });

  const handleSuccessfulAuth = async (data, res) => {
    console.log("I am in handleSuccessfulAuth");
    if (data.auth) {
      localStorage.setItem("loginExpiration", moment().add(3, "hours"));
      localStorage.setItem("appDescription", pjson.description);
      localStorage.setItem("appVersion", pjson.version);
      await setExternalUserInfo(data.u);

      console.log(
        "externalUserInfo => ",
        JSON.parse(localStorage.getItem("externalUserInfo"))
      );
      if (localStorage.getItem("externalUserInfo") === null) {
        await setDropdowns(null);
        navigate("/registration");
      } else if (
        JSON.parse(localStorage.getItem("externalUserInfo"))?.status ===
        "pending"
      ) {
        //&& !JSON.parse(localStorage.getItem('externalUserInfo'))?.isAdmin
        showAlertInfo(
          "Registration has been submitted, please wait for the registration process to be completed."
        );
      } else if (
        JSON.parse(localStorage.getItem("externalUserInfo"))?.status ===
        "declined"
      ) {
        //&& !JSON.parse(localStorage.getItem('externalUserInfo'))?.isAdmin
        showErrorInfo(
          "Registration was declined. Please reach out to the Client if you feel you have received this message in error. This request has been fulfilled by Optum on behalf of the Client."
        );
      } else {
        if (
          JSON.parse(localStorage.getItem("externalUserInfo")).touAccepted ===
          true
        ) {
          let r = await fetch(
            localStorage.getItem("apiUrl") +
              `/getRatings?user=${localStorage.getItem(
                "u"
              )}&appName=Chart3p External`
          );
          let rj = await r.json();
          if (rj && rj.rows && rj.rows.length > 0) {
            //already has entry in ratings
            console.log("already has entry in ratings");
            localStorage.setItem(
              "appRating",
              JSON.stringify(rj.rows[0].rating)
            );
          }
          setState((p) => ({ ...p, auth: true }));
          navigate("/in/dashboard");
        } else {
          navigate("/tou");
        }
      }
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    localStorage.clear();
    console.log(
      "Localstorage apiUrl before setting => ",
      localStorage.getItem("apiUrl")
    );
    localStorage.setItem("apiUrl", process.env.REACT_APP_API_URL);
    console.log(
      "Localstorage apiUrl after setting => ",
      localStorage.getItem("apiUrl")
    );
    console.log("ENV apiUrl => ", process.env.REACT_APP_API_URL);
    localStorage.setItem("environment", state.environment);
    localStorage.setItem(
      "navHeader",
      state.environment === "Prod" ? "" : state.environment
    );
    console.log("pjson description =>", pjson.description);
    let data = {};
    data.u = state.u;
    data.p = state.p;
    data.app = `${pjson.description} v${pjson.version}`;
    console.log("Localstorage before post =>", localStorage);
    let token = await generateToken();
    localStorage.setItem("token", token);
    fetch(`${process.env.REACT_APP_API_URL}/aaa`, {
      //localStorage.getItem('apiUrl')
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        //do a few checking on this one.
        if (!res.authenticated) {
          // some alert for wrong password
          showErrorInfo(
            "Login Failure: You may have typed the wrong password or you may not belong to the group authorized to use the application. Please go to https://secure.optum.com."
          );
          localStorage.clear();
        } else {
          localStorage.setItem("auth", res.authenticated);
          data.auth = res.authenticated;
          localStorage.setItem("displayName", res.displayName);
          data.displayName = res.displayName;
          localStorage.setItem("u", res.cn);
          data.u = res.cn;
          localStorage.setItem("userkey", res.cn);
          data.userkey = res.cn;
          data.userEmail = res.mail;
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("userEmail", res.mail);
          localStorage.setItem("memberOf", res.memberOf);
          localStorage.setItem("userName", `${res.sn}, ${res.givenName}`);
          localStorage.setItem(
            "globalGroups",
            JSON.stringify(
              _.map(res.memberOf, (v) => v.substring(3, v.indexOf(",")))
            )
          );
          if (
            _.contains(
              JSON.parse(localStorage.getItem("globalGroups")),
              "o_ois_datae"
            )
          ) {
            localStorage.setItem("developer", true);
            console.log("developer => ", localStorage.getItem("developer")); //JSON.parse
          }
          delete data.p;
          console.log("Data => ", data);
          handleSuccessfulAuth(data, res);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setExternalUserInfo = async (u) => {
    try {
      let res = await fetch(
        `${localStorage.getItem(
          "apiUrl"
        )}/getXUsers?preferred_username=${u}&idp=Optum AD`
      );
      let resj = await res.json();
      if (resj.rows.length > 0) {
        localStorage.setItem("externalUserInfo", JSON.stringify(resj.rows[0]));
        localStorage.setItem("userType", resj.rows[0].userType);
        localStorage.setItem("isAdmin", resj.rows[0].isAdmin);
        localStorage.setItem("vendorName", resj.rows[0].vendorName);
        localStorage.setItem("clientName", resj.rows[0].clientName);
        await setDropdowns(resj.rows[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const setDropdowns = async (user) => {
    try {
      let res;
      if (user) {
        res = await fetch(
          `${process.env.REACT_APP_API_URL}/getDropdowns2?${
            user.userType === "vendor" ? "chartSource" : "clientCode"
          }=${user.userType === "vendor" ? user.vendorName : user.clientName}`
        );
      } else {
        res = await fetch(`${process.env.REACT_APP_API_URL}/getDropdowns2`);
      }
      let resj = await res.json();
      console.log("dropsDowns resj =>", resj);
      if (resj.rows.length > 0) {
        localStorage.setItem("dropdowns", JSON.stringify(resj.rows));
        return resj.rows[0];
      } else {
        localStorage.setItem("dropdowns", JSON.stringify([]));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleMSIDChange = (e) => {
    // setU(e.target.value)
    setState((p) => ({ ...p, u: e.target.value }));
  };

  const handleChange = (event) => {
    // setP(event.target.value)
    setState((p) => ({ ...p, p: event.target.value }));
  };

  const logoffHandler = () => {
    let forPosting = {
      product: "Chart3P External",
      class: "SECURITY_AUDIT",
      sourceUserMSID: !state.u || state.u === "" ? "User unknown" : state.u,
      message: "TerminateUserSession:SUCCESS",
      severity: "INFO",
    };
    post6c(forPosting).then(console.log).catch(console.log);
    localStorage.clear();
    window.location.replace("/");
  };

  const hideRegistrationModal = () => {
    setState((p) => ({
      ...p,
      showRegistration: false,
    }));
  };

  const showAlertInfo = (message) => {
    setState((s) => ({
      ...s,
      alertInfo: message,
    }));
  };

  const onCloseAlertInfo = () => {
    setState((p) => ({
      ...p,
      alertInfo: null,
    }));
  };

  const showErrorInfo = (message) => {
    setState((s) => ({
      ...s,
      errorInfo: message,
    }));
  };

  const onCloseErrorInfo = () => {
    setState((p) => ({
      ...p,
      errorInfo: null,
    }));
  };

  return (
    <>
      <Snackbar
        open={state.alertInfo !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        style={{ marginBottom: "15%" }}
        onClose={onCloseAlertInfo}
      >
        <Alert variant="filled" onClose={onCloseAlertInfo} severity="success">
          {state.alertInfo}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state.errorInfo !== null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        style={{ marginBottom: "15%" }}
        onClose={onCloseErrorInfo}
      >
        <Alert variant="filled" onClose={onCloseErrorInfo} severity="error">
          {state.errorInfo}
        </Alert>
      </Snackbar>
      <Container
        className="justify-content-center"
        style={{ paddingTop: "200px" }}
      >
        <Row>
          <Col lg={4}></Col>
          <Col lg={4}>
            <Card className="justify-content-center">
              <Card.Header
                as="h1"
                style={{
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                  height: "58px",
                  color: "OrangeRed",
                  textShadow: "1px 1px 4px gray",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/OptumO.png"}
                  align="middle"
                  height="50px"
                  marginRight="10px"
                  alt="Optumlogo"
                />
                &nbsp;Chart3P
              </Card.Header>
              <Card.Body
                className="justify-content-center"
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                <form onSubmit={submitHandler}>
                  <div className="form-group">
                    <label htmlFor="u">MS ID</label>
                    <input
                      type="text"
                      autoFocus
                      className="form-control"
                      id="u"
                      name="u"
                      aria-describedby="emailHelp"
                      placeholder="Enter MS Logon"
                      onChange={handleMSIDChange}
                      value={state.u}
                    />
                  </div>
                  <div className="form-group" style={{ marginTop: 10 }}>
                    <label htmlFor="p">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="p"
                      name="p"
                      placeholder="Your Password"
                      onChange={handleChange}
                      value={state.p}
                    ></input>
                  </div>
                  <div style={{ textAlign: "center", marginTop: 15 }}>
                    <button
                      type="submit"
                      className="btn btn-warning"
                      id="login"
                      size="sm"
                    >
                      Logon
                    </button>
                    &nbsp;
                    <button
                      type="button"
                      className="btn btn-warning"
                      id="logoff"
                      onClick={logoffHandler}
                      size="sm"
                    >
                      Logoff
                    </button>
                  </div>
                </form>
              </Card.Body>
              <Card.Footer
                className="justify-content-center"
                style={{ textAlign: "center" }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/optum_2x.png"}
                  alt="optum logo"
                  height="30px"
                  title={pjson.description + " v" + pjson.version}
                ></img>{" "}
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={4}></Col>
        </Row>
        <Modal
          size="sm"
          show={state.showRegistration}
          onHide={hideRegistrationModal}
          dialogClassName="modal-wide2"
        >
          <Modal.Header>
            <div style={{ textAlign: "center", width: "100%" }}>
              <h3 style={{ color: "#e77721", padding: 5 }}>
                Registration Form
              </h3>
            </div>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Registration
              data={state.data}
              hideRegistrationModal={hideRegistrationModal}
              showAlertInfo={showAlertInfo}
            ></Registration>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default Login;
