import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert, Navbar, Nav, NavDropdown, Container, Modal} from 'react-bootstrap' 
import { Link, useLocation } from 'react-router-dom'
import { LinkContainer } from "react-router-bootstrap";
import { post6c } from './utils';
import localStorage from 'react-secure-storage';
import { Rating } from "@mui/material";

const NavBarTop = (props) => {
    const location = useLocation(); // once ready it returns the 'window.location' object
    const [url, setUrl] = useState(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [rate, setRate] = useState(localStorage.getItem("appRating"));

    useEffect(() => {
        console.log(location.pathname)
      setUrl(location.pathname);
    }, [location]);
    const titleClick=(e)=>{
        console.log('title was clicked')
    }
    const handleLogoff = ()=>{
        let forPosting={
            "product": "Chart3P",
            "class": "SECURITY_AUDIT",
            "sourceUserMSID": localStorage.getItem('u')?localStorage.getItem('u'):'User unknown',
            "message": "TerminateUserSession:SUCCESS",
            "severity": "INFO"
          }
        post6c(forPosting).then(console.log).catch(console.log)
        localStorage.clear()
        window.location.replace('/')
    }

    useEffect(() => {
        setTimeout(function () {
            if (!localStorage.getItem("appRating")) {
                handleShow();
            }
        }, 180000);
    }, []);

    const capitalize=(str)=>{
        if (typeof str !== 'string') return ''
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const handleChangeRate = (e, newValue) => {
        setRate(newValue);
        if (newValue && newValue != 0) {
            localStorage.setItem("appRating", newValue);
            let forPosting = { user: localStorage.getItem("u"), name: localStorage.getItem("displayName"), rating: newValue, dateTime: new Date() };
            fetch(localStorage.getItem("apiUrl") + "/postRating", {
                method: "POST",
                headers: new Headers({ "Content-Type": "application/json" }),
                body: JSON.stringify(forPosting),
                redirect: "follow",
            })
                .then((r) => {
                    handleClose();
                })
                .catch(console.log);
        }
    };

    const termsOfUseView=()=>{
        window.open(`${process.env.PUBLIC_URL}/Online Services Terms of Use.pdf`, 'TermsOfUseViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    }

    const privacyPolicyView=()=>{
        window.open(`${process.env.PUBLIC_URL}/Online Services Privacy Policy.pdf`, 'PrivacyPolicyViewer', 'height=540,width=960,location=off,scrollbars=on,status=off,resizable=on')
    }
    
    // console.log(JSON.parse(localStorage.getItem('isAdmin')))
    return ( 
        <>
            <Navbar  variant="dark" bg='dark' expand fixed='top' style={{paddingTop:'0px', paddingBottom:'0px', margin:'0px', fontSize: '.9vw'}} >
                <Navbar.Brand ><Link to='/' onClick={titleClick} style={{textDecoration:'none'}}><h4 style={{color:'orangered', paddingLeft:'10px', margin:'0px', textDecoration:'none'}}><img src={process.env.PUBLIC_URL + '/OptumO.png'} valign='center' height='32px' alt='Optumlogo' title={localStorage.getItem('appDescription') + ' v' + localStorage.getItem('appVersion')} style={{textDecoration:'none'}}/>&ensp;Chart3P</h4></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav fill>
                    {/* <Nav.Link href='/in/'>Home</Nav.Link> */}
                    <Nav.Link href='/in/dashboard'  style={{color: url === "/in/dashboard" ? 'white' : ''}}>Dashboard</Nav.Link>
                    <Nav.Link href='/in/search'  style={{color: url === "/in/search" ? 'white' : ''}}>Search</Nav.Link>
                </Nav>
                <Container style={{paddingRight:'0px', marginRight:'0px'}}>
                    
                    <Nav style={{textAlign:'right',justifyContent:'end', alignContent: 'center'}}>
                        <Nav.Link href=''  >{localStorage.getItem('userType')?capitalize(localStorage.getItem('userType')) + ": " :''} {localStorage.getItem('userType')==='client'?localStorage.getItem('clientName'):localStorage.getItem('vendorName')}</Nav.Link>
                    
                        {/* {JSON.parse(localStorage.getItem('isAdmin'))?
                            <Nav.Link href='/in/admin'  style={{color: url === "/in/admin" ? 'white' : ''}}>ADMIN</Nav.Link>
                        :null} */}
                        <NavDropdown title={localStorage.getItem('displayName')} id="SMS-nav-dropdown">
                            <NavDropdown.Item target="_blank" onClick={termsOfUseView} >Terms of Use</NavDropdown.Item>
                            <NavDropdown.Item target="_blank" onClick={privacyPolicyView} >Privacy Policy</NavDropdown.Item>
                            <NavDropdown.Item onClick={handleShow}>
                                    Rate Application
                            </NavDropdown.Item>
                            <NavDropdown.Item onClick={handleLogoff}>Logoff {localStorage.getItem('navHeader')}</NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link className='mr-sm-2' eventKey={7} style={{textAlign:'right'}}  onClick={handleLogoff}>&emsp;{localStorage.getItem('displayName')} &emsp; Logoff {localStorage.getItem('navHeader')}</Nav.Link> */}
                    </Nav>
                </Container>
                <Navbar.Brand  className="mr-sm-2" href="#home" style={{textAlign:'right', marginRight:10}}>&ensp;<img src={process.env.PUBLIC_URL + '/optum-logo-dark-color.png'} height='32px' alt='Optumlogo' title={localStorage.getItem('appDescription') + ' v' + localStorage.getItem('appVersion')}/></Navbar.Brand>
                </Navbar.Collapse>
            </Navbar>
            <Modal show={show} size="sm" onHide={handleClose} centered={true}>
                <Modal.Header si style={{ color: "var(--optum-orange)", textAlign: "center" }} closeButton>
                    <Modal.Title style={{ width: "100%" }}>App Rating</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        <p>Please rate your app experience.</p>
                        <Rating name="simple-controlled" size="large" value={rate} onChange={handleChangeRate} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
     );
}
 
export default NavBarTop;

