import React from "react";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import {
  Button,
  Card,
  Typography,
  CardContent,
  CardActions,
  CardMedia,
  Divider,
  Alert,
  Snackbar
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import localStorage from 'react-secure-storage';

const Landing = () => {
  const navigate = useNavigate(); // useNavigate hook - navigate to other screens
  const { state: locationState } = useLocation(); // useLocation hook - receive info and params from navigation
  const [state, setState] = useState({
    showMessages: true,
    alertSuccess: null,
    alertError: null
  });
  const handleLogin = () => {
    localStorage.clear()
    window.location.replace("/login");
  };
  const handleLoginOHID = () => {
    localStorage.clear()
    window.location.replace("/auth");
  };

  useEffect(() => {
    // console.log('locationState => ', locationState); // Display state from useLocation hook

    if (locationState) {
      // Display a success message
      if (locationState.message) {
        if(locationState.success) {
          setState({ ...state, alertSuccess: locationState.message });
        } else {
          setState({ ...state, alertError: locationState.message });
        }
      }

      // Clears navigation params
      navigate(location.pathname, {});
    }
  }, []);

  const onCloseAlertSuccess = () => {
    setState({ ...state, alertSuccess: null });
  };

  const onCloseAlertError = () => {
    setState({ ...state, alertError: null });
  };

  const imageStyle = {
    paddingBottom: 0,
    paddingTop: "10%",
    paddingLeft: "10%",
    paddingRight: "10%",
  };

  return (
    <Container
      fluid
      style={{
        placeContent: "center",
        alignItems: "center",
        flexDirection: "row",
        boxSizing: "border-box",
        display: "flex",
        marginTop: "14%",
      }}
    >
      <Snackbar
        open={state && state.alertSuccess !== null}
        anchorOrigin={{ vertical: "middle", horizontal: "center" }}
        style={{marginBottom: '10%'}}
        onClose={onCloseAlertSuccess}
      >
        <Alert
          variant="filled"
          onClose={onCloseAlertSuccess}
          severity="success"
        >
          {state.alertSuccess}
        </Alert>
      </Snackbar>
      <Snackbar
        open={state && state.alertError !== null}
        anchorOrigin={{ vertical: "middle", horizontal: "center" }}
        style={{marginBottom: '10%'}}
        onClose={onCloseAlertError}
      >
        <Alert
          variant="filled"
          onClose={onCloseAlertError}
          severity="error"
        >
          {state.alertError}
        </Alert>
      </Snackbar>
      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "100%",
          boxSizing: "border-box",
          maxWidth: "80%",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "40%",
            }}
          >
            <p
              style={{
                color: "#e77721",
                padding: 5,
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              Choose your Identity Provider
              <Divider
                orientation="horizontal"
                variant="fullWidth"
                style={{
                  borderColor: "#404040",
                  borderBottomWidth: "2px",
                  marginTop: 10,
                }}
              />
            </p>
          </div>
        </div>
        <div>
          <div
            style={{
              flexDirection: 1,
              flexWrap: "wrap",
              boxSizing: "border-box",
              display: "flex",
              placeContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ flexBasis: "20%", padding: 25 }}>
              <Card
                sx={{
                  width: 300,
                  height: 300,
                  margin: "auto",
                }}
                elevation={3}
              >
                <CardMedia
                  component="img"
                  sx={{
                    paddingBottom: "10%",
                    paddingTop: "10%",
                    paddingLeft: "15%",
                    paddingRight: "15%",
                  }}
                  image={process.env.PUBLIC_URL + "ohid.png"}
                />
                <CardContent style={{ height: 75 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#888",
                    }}
                  >
                    One HealthCare ID
                  </Typography>
                </CardContent>
                <CardActions>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 12,
                        padding: "10px 15px",
                        backgroundColor: "#0A8579",
                        color: "white",
                      }}
                      onClick={handleLoginOHID}
                      style={{ margin: 5, width: "100%" }}
                    >
                      Login
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </div>
            <div style={{ flexBasis: "20%", padding: 25 }}>
              <Card
                sx={{
                  width: 300,
                  height: 300,
                  margin: "auto",
                }}
                elevation={3}
              >
                <CardMedia
                  component="img"
                  sx={{
                    paddingBottom: "10%",
                    paddingTop: "10%",
                    paddingLeft: "25%",
                    paddingRight: "25%",
                  }}
                  image={process.env.PUBLIC_URL + "optum-logo-dark-color.png"}
                />
                <CardContent style={{ height: 75 }}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                      color: "#888",
                    }}
                  >
                    Optum Internal Employee
                  </Typography>
                </CardContent>
                <CardActions>
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{
                        fontWeight: "bold",
                        fontSize: 12,
                        padding: "10px 15px",
                      }}
                      onClick={handleLogin}
                      style={{ margin: 5, width: "100%" }}
                    >
                      Login
                    </Button>
                  </div>
                </CardActions>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Landing;
